import React, { useState, Component } from 'react';
import { Link } from 'gatsby'
import { Container, ButtonGroup, Row, Col } from 'react-bootstrap';
import classNames from 'classnames';
import ReactMarkdown from "react-markdown"
import CalendlyCTAOne from '@components/calendly/global-cta-one';
import CalendlyCTATwo from '@components/calendly/global-cta-two';
import ReapitWiget from '@components/reapit-widget/reapit'; 

/**
 * Assets
 */
import './assets/styles/_index.scss';

/**
 * Components
 */
import Video from '@components/video/video';
import NoVideo from '@components/video/without-video';
import ModalVideo from '@components/video/video-modal';
import Relative from '@components/relative/relative';
import Search from '@components/search/search';


const no_image = "/images/no-image.png"

const Intro = (props,{className}) => {

      if(props.Speak_with != null)
      {
        if(props.Speak_with.Image == null)
        {
          var imgURl = no_image
        }
        else
        {
          var imgURl = props.Speak_with.Image.publicURL 
        }
      }


      if(props.CTA_1_URL != null) {
        if ((props.CTA_1_URL.match('http')) || (props.CTA_1_URL.match('https'))) {
          var CTA_1 = "external"
        }
        else {
          var CTA_1 = "internal"
        }
      }

      if(props.CTA_2_URL != null) {
        if ((props.CTA_2_URL.match('http')) || (props.CTA_2_URL.match('https'))) {
          var CTA_2 = "external"
        }
        else {
          var CTA_2 = "internal"
        }
      }

      var Calendly = props.Calendly

      if((props.Speak_with != null) && (props.Speak_with != "")) {
        if(props.Speak_with.Calendly_Link != null) {
          var Calendly = props.Speak_with.Calendly_Link
        }
      }


  return (
    <div className={`intro ${props.Custom_CSS || ''}`}>
      <Relative background="#fff">
        <Container>
          <Row>
            <Col className="is-static order-lg-1" lg={{span: 1, offset: 4}}>
              {props.Upload_Video != null && props.Upload_Video != "#" &&
                <Video 
                  image={props.Banner_Image} 
                  video={props.Upload_Video.publicURL}
                  height={props.height}
                  width="58%"
                  imgsize={props.size}
                  align="right"
                />
              }
              {props.Embed_Video_URL != null && props.Embed_Video_URL != "#" &&
                <ModalVideo 
                  image={props.Banner_Image} 
                  video={props.Embed_Video_URL}
                  name={props.Title}
                  height={props.height}
                  width="58%"
                  imgsize={props.size}
                  align="right"
                />
              }
              {props.Upload_Video == null && props.Embed_Video_URL == null &&
                <NoVideo 
                  image={props.Banner_Image} 
                  height={props.height}
                  width="58%"
                  imgsize={props.size}
                  align="right"
                />
              }
            </Col>
            <Col lg={4} className="align-self-center text-box">
              {props.Title != null &&
                <h2>{props.Title}</h2>
              }
              {props.Content !== null && props.Content !== "" &&
                <p>
                  <ReactMarkdown source={props.Content} />
                </p>
              }
              <ButtonGroup vertical>
                {props.CTA_1_Label !== null && props.CTA_1_URL !== "#calendly" && props.CTA_1_URL !== "#search" && props.CTA_1_URL !== "#reapit" &&
                <>
                {CTA_1 == "internal" &&
                  <Link to={`/${props.CTA_1_URL}`} className="primary-button btn btn-primary">{props.CTA_1_Label}</Link>
                }
                {CTA_1 == "external" &&
                  <a href={props.CTA_1_URL} target="_blank" className="primary-button btn btn-primary">{props.CTA_1_Label}</a>
                }
                </>
                }


                {props.CTA_1_Label !== null && props.CTA_1_URL === "#calendly" &&
                <CalendlyCTAOne url={Calendly}/>
                }

                {props.CTA_1_Label !== null && props.CTA_1_URL === "#search" &&
                  <Search label={props.CTA_1_Label} class="primary-button btn btn-primary"/>
                }

                {props.CTA_1_Label !== null && props.CTA_1_URL === "#reapit" &&
                  <ReapitWiget class="primary-button btn btn-primary reapit-val-btn" label={props.CTA_1_Label}/>
                }


                {props.CTA_2_Label != null && props.CTA_2_URL !== "#calendly" && props.CTA_2_URL !== "#search" && props.CTA_2_URL !== "#reapit" &&

                <>
                {CTA_2 == "internal" &&
                  <Link to={`/${props.CTA_2_URL}`} className="btn btn-outline-primary">{props.CTA_2_Label}</Link>
                }
                {CTA_2 == "external" &&
                  <a href={props.CTA_2_URL} target="_blank" className="btn btn-outline-primary">{props.CTA_2_Label}</a>
                }
                </>
                }


                {props.CTA_2_Label !== null && props.CTA_2_URL === "#calendly" &&
                <CalendlyCTATwo url={Calendly}/>
                }

                {props.CTA_2_Label !== null && props.CTA_2_URL === "#search" &&
                  <Search label={props.CTA_2_Label}  class="btn btn-outline-primary"/>
                }
                {props.CTA_2_Label !== null && props.CTA_2_URL === "#reapit" &&
                  <ReapitWiget class="btn btn-outline-primary reapit-val-btn" label={props.CTA_2_Label}/>
                }


              </ButtonGroup>
              {props.Show_Google_Rating == true &&
                <div className="review-count">
                  <img src="/images/google.png" alt="google" />
                  <p>Rated <strong>4.5+/5</strong> by over <a href="/">300 reviews</a></p>
                </div>
              }
              {props.Speak_with != null &&
              <Row>
                <Col className="col-auto">
                  <img src={imgURl} alt="avatar" className="intro-avatar"/>
                </Col>
                <Col>
                  <p>Speak with {props.Speak_with.Name.split(" ")[0]} on<br/> <a href={`tel:+${props.Speak_with.Phone}`}>{props.Speak_with.Phone}</a> or <Link to="/contact">email</Link> </p>
                </Col>
              </Row>
              }

            </Col>
          </Row>
        </Container>
      </Relative>
    </div>
  )
}

export default Intro;
