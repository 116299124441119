import React from "react";
import Helmet from 'react-helmet';
import { Container } from 'react-bootstrap';
import ReactMarkdown from "react-markdown"

/**
 * Layouts
 */
import Layout from '@layouts/default';

/**
 * Components
 */
import Intro from '@components/intro/intro';
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs';
import OfficeInfo from '@components/property-info/office-info';

import { graphql } from 'gatsby'
import get from 'lodash/get'
import SEO from "../components/seo"



const no_image = "/images/no-image.png"


class OfficeDetailsTemplate extends React.Component {

  render() {

    const Page = get(this, 'props.data.strapiOffices')
    const Team = get(this, 'props.data.allStrapiTeams.edges')
    const Calendly = get(this, 'props.data.strapiGlobalConfig.Calendly_Link')


    return (
      <Layout>
        <Helmet 
          bodyAttributes={{
            class: 'static office-details templates-office-template-js'
          }}
        />
        <SEO title={Page.Meta_Title} description={Page.Meta_Description} />
        <Intro Title={Page.Header.Banner_Title} Content={Page.Header.Content} CTA_1_Label={Page.Header.CTA_1_Label} CTA_1_URL={Page.Header.CTA_1_URL} CTA_2_Label={Page.Header.CTA_2_Label} CTA_2_URL={Page.Header.CTA_2_URL} Show_Google_Rating={Page.Header.Show_Google_Rating} Upload_Video={Page.Header.Upload_Video} Banner_Image={Page.Header.Banner_Image} Embed_Video_URL={Page.Header.Embed_Video_URL} Speak_with={Page.Header.Speak_with} height="742px" Calendly={Calendly}/>

        <OfficeInfo data={Page} Team={Team}/>
        
        <Breadcrumbs Page_1={Page.Office_Name}/>
      </Layout>
    )
  }
}

export default OfficeDetailsTemplate;


export const pageQuery = graphql`
  query OfficeDetailsTemplateSlug($slug: String!) {

    strapiOffices(URL: { eq: $slug }) {
    Office_Id
    Address
    Email
    Latitude
    Longitude
    Meta_Description
    Meta_Title
    Office_Name
    Opening_Hours
    Phone
    URL
    Latitude
    Longitude
    About_Office
    Office_Image {
      publicURL
    }
    Header {
      Banner_Title
      CTA_1_Label
      CTA_1_URL
      CTA_2_Label
      CTA_2_URL
      Content
      Show_Google_Rating
      Embed_Video_URL
      Upload_Video {
        publicURL
      }
      Banner_Image {
        internal {
          description
        }
        publicURL
      }
    }
  }


  allStrapiTeams(filter: {Publish: {eq: true}, offices: {elemMatch: {URL: {eq: $slug}}}}) {
    edges {
      node {
        Category
        Designation
        Email
        Embed_Video_URL
        Name
        Phone
        Image {
        internal {
          description
        }
        publicURL
        }
      }
    }
  }


  strapiGlobalConfig {
    Calendly_Link
  }

  }
`